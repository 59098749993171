import React from 'react';
import "./Overview.css";

import Website from "../../assests/Website.jpg";

function Overview({ number }) {
    return (
        <div className="card1">
            <div>
            <h1>Overview</h1>
            </div>
            <div className="meat">

            
            <img src={Website} alt ="Architecture" />
            {number}
            <h2> The website utilizes a web server, a load balancer and a content distribution network.  Users can only access the website through https.  Http requests are redirected to https.
                 
            </h2>
            </div>
            
      </div>
    );
  }
  
  export default Overview;