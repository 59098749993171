import React from 'react';
import "./Integrity2.css"
import DynamoDB from "../../assests/DynamoDB.JPG";

function Integrity2( ) {
    return (
        <div className="card2">
            <div>
            <h1>Integrity</h1>
            </div>
            <div className="meat">
            <img src={DynamoDB} alt ="Architecture" />

            <h2> Integrity is maintained by encrypted connections. CRUD functions are escaped and validated. Access control to the database is only through proper authenication for the user and administrator.  
            </h2>
</div>
            
            
      </div>
    );
  }
  
  export default Integrity2;