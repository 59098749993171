import React from 'react';
import Welcome from "./welcome/Welcome"
import './App.css';
import { Switch, Route } from "react-router-dom";
function App() {
  return (
    <div className="App">

      <Switch>
        <Route exact path ="/" component={Welcome} />
      


      </Switch>

    </div>
  );
}

export default App;
