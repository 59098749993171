import React from 'react';
import "./Confidentiality2.css"
import DynamoDB from "../../assests/DynamoDB.JPG";

function Confidentiality2( ) {
    return (
        <div className="card2">
            <div>
            <h1>Confidentiality</h1>
            </div>
            <div className="meat">
            <img src={DynamoDB} alt ="Architecture" />

            <h2> Confidentiality of the technology stack is maintained by restrict identity and access management.  Data in transit is encrypted and any http request
                is redirected to https. Data at rest is encrypted.  Only properly authenicated users can access their data. Input functions are validated.  
            </h2>
</div>
            
            
      </div>
    );
  }
  
  export default Confidentiality2;