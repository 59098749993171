import React from 'react';
import "./Wave.css"


function Wave() {
    return (
        <div className="wave-div">
      </div>
    );
  }
  
  export default Wave;