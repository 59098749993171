import React from 'react';
import "./Availability2.css"
import DynamoDB from "../../assests/DynamoDB.JPG";

function Availability( ) {
    return (
        <div className="card2">
            <div>
            <h1>Availability</h1>
            </div>
            <div className="meat">
            <img src={DynamoDB} alt ="Architecture" />

            <h2> Avaiablity is maintained by strict access controls/authentication. Continous monitoring with email alerts to notify when something needs attention. Secure backups provide easy recovery of the database.
                Redundant infrastructure with load balancing has been implmented.   
            </h2>
</div>
            
            
      </div>
    );
  }
  
  export default Availability;