import React from 'react';
import "./Top.css"






function Top() {
    return (
        <div className="title-div">
          <h1 className="jeff">Jeff Snyder</h1>
      </div>
    );
  }
  
  export default Top;