import React from 'react';
import "./Availability.css"
import AegisWebsiteArchitect from "../../assests/AegisWebsiteArchitect.JPG";

function Availability( ) {
    return (
        <div className="card2">
            <div>
            <h1>Availability</h1>
            </div>
            <div className="meat">
            <img src={AegisWebsiteArchitect} alt ="Architecture" />

            <h2> Avaiablity is maintained by utilizing Amazon's content delivery network. The CDN is pointed to a priavte load balancer.  The load balancer
                balances the traffic to the web servers. 
            </h2>
</div>
            
            
      </div>
    );
  }
  
  export default Availability;