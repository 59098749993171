import React from 'react';
import "./Confidentiality.css"
import AegisWebsiteArchitect from "../../assests/AegisWebsiteArchitect.JPG";

function Confidentiality( ) {
    return (
        <div className="card2">
            <div>
            <h1>Confidentiality</h1>
            </div>
            <div className="meat">
            <img src={AegisWebsiteArchitect} alt ="Architecture" />

            <h2> Confidentiality of the technology stack is maintained by restrict identity and access management.  Data in transit is encrypted and any http request
                is redirected to https. 
            </h2>
</div>
            
            
      </div>
    );
  }
  
  export default Confidentiality;