import React from 'react';
import "./Welcome.css"
import Top from "../component/Top"
import About from "../component/About"
import Projects from "../component/Projects"
import Contact from "../component/Contact"
import Wave from "../component/Wave"
import Skills from "../component/Skills"






function Welcome() {
    return (

    
        <div> 
               
            <div >
                <Top />
            </div>
            <div>
                <Projects />

            </div>
            <div>
                <Skills />
            </div>
            <div >
                <About />
            </div>
            <div>
                <Wave />
            </div>
            <div>
                <Contact />
            </div>
            
            
            </div>
    );
  }
  
  export default Welcome;