import React from 'react';
import "./Integrity.css"
import AegisWebsiteArchitect from "../../assests/AegisWebsiteArchitect.JPG";

function Integrity( ) {
    return (
        <div className="card2">
            <div>
            <h1>Integrity</h1>
            </div>
            <div className="meat">
            <img src={AegisWebsiteArchitect} alt ="Architecture" />

            <h2> Integrity is maintained by signed certificates issued by Amazon.  The web servers are monitored for high traffic volume and anomalous connections.  
            </h2>
</div>
            
            
      </div>
    );
  }
  
  export default Integrity;