import React from 'react';
import Carousel from "react-elastic-carousel"
import "./Projects.css";
import Overview from './AegisCard/Overview';
import Confidentiality from './AegisCard/Confidentiality';
import Integrity from './AegisCard/Integrity';
import Availability from './AegisCard/Availability';
import Overview2 from './DynamoDBCard/Overview2';
import Confidentiality2 from './DynamoDBCard/Confidentiality2';
import Integrity2 from './DynamoDBCard/Integrity2';
import Availability2 from './DynamoDBCard/Availability2';


function Projects() {
    return (
        <div id="projects" className="project-div">
            <h1 className="project-title">projects.</h1>
            <div className="flex-box">
              <h1>Aegis Web Site</h1>
              
              <Carousel>
                <Overview/>
                <Confidentiality/>
                <Integrity />
                <Availability />
              </Carousel>
              <a className="project-link" href="https://www.aegiswebsystem.com" target="_blank" rel="noopener noreferrer"><h2>Visit Aegis Web System</h2></a>
              </div>
              <div className="flex-outter">
              <div className="flex-box">
              <h1>Full Stack Deployment with DynamoDB</h1>
              <Carousel>
                <Overview2/>
                <Confidentiality2/>
                <Integrity2 />
                <Availability2 />
              </Carousel>
              </div>
              </div>


        </div>
    );
  }
  
  export default Projects;