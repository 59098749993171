import React from 'react';
import "./Overview2.css";

import SocialMedia from "../../assests/SocialMedia.JPG";

function Overview2({ number }) {
    return (
        <div className="card1">
            <div>
            <h1>Overview</h1>
            </div>
            <div className="meat">

            
            <img src={SocialMedia} alt ="Architecture" />
            {number}
            <h2> The Social Media web application uses, a content distribution network, load balancer, web server and NOSQL database.  
              Users authenticate with the server via jsonweb tokens.  DynamoDB is used as the backend server for scalability and reliability.
              The webserver only talks to the load balancer and database endpoint. 
                 
            </h2>
            </div>
            
      </div>
    );
  }
  
  export default Overview2;