import React from 'react';
import "./Contact.css"
import emailjs from "emailjs-com"

function Contact() {
  function sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm('service_126w2sp', 'template_33usgx8', e.target, 'user_a5QQJ1CXDyZ2ltQJFmW0O')
    .then((result) => {
    console.log(result.text);
    }, (error) => {
    console.log(error.text);
    });
    e.target.reset()
    }



    return (
        <div id="contact"className="contact-div">
            <h1 className="contact-title">contact.</h1>
            <form onSubmit={sendEmail}>

            
            <div className="row pt-5 mx-auto">
              <div className="input-fields">
                <input type="text" className="form-control" placeholder="Name" name="name"  id="name"/>
              </div>
              <div className="input-fields">
                <input type="text" className="form-control" placeholder="Email Address" name="email" id="email"/>
              </div>
              <div className="input-fields">
                <input type="text" className="form-control" placeholder="Subject" name="subject" id="subject"/>
              </div>
              <div className="message-field">
                <textarea className="message-control" placeholder="Your message" name="message" id="message"></textarea>

              </div>
              <div className="submit-field">
                <input type="submit" className="submit-control" value="Send Message" ></input>

              </div>


            </div>
            </form>
      </div>
    );
  }
  
  export default Contact;