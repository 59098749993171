import React from 'react';
import "./Skills.css";


function Skills() {
    return (
        <div>
            <div>
                <h1 className="skills-title">skills.</h1>
            </div>
            <div className="skills-box">
            <div className="skills">
                <a className="skills-link" href="https://github.com/forexsnyder/Python" target="_blank" rel="noopener noreferrer">Python Repo</a>
            </div>
            <div className="skills" >
                
                <a className="skills-link" href="https://github.com/forexsnyder/Terraform" target="_blank" rel="noopener noreferrer">Terraform Repo</a>
            </div>
            <div className="skills">
                <a className="skills-link" href="https://github.com/forexsnyder" target="_blank" rel="noopener noreferrer">React.js Projects</a>
            </div>
            <div className="skills">
                <a className="skills-link" href="https://github.com/forexsnyder/Frameworks" target="_blank" rel="noopener noreferrer">Frameworks Repo</a>
            </div>

            </div>

        </div>
    )
  }
  
  export default Skills;