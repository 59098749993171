import React from 'react';
import "./About.css"


function About() {
    return (
        <div  className="main-div">
            <div className="inside-wave"></div>
            <h1 id="about" className="about-title">about.</h1>
            <div >
            <h2>I am a Coast Guard veteran who served as an IT for 10 years.  I enjoyed working in high stress environments where selfless service was the norm.  When I was stationed in the Middle East, I extended my yearlong tour for 3 months to help the other IT’s complete a project they would have otherwise struggled to accomplish. 
                I have learned how to maintain and secure an enterprise environment with little to no impact on daily operations.
 
                I recognize the devastating consequences a poor cybersecurity posture can have on an organization. I am looking for an opportunity to exercise my cyber and technology skills to enable my teammates achieve the success they are looking for.  I want to leverage cloud technology to help an organization protect its data, have a solid business continuity plan, and lower operating costs. My name is Jeff Snyder, I am a cloud security engineer.
            </h2>
            <div className="step-one">
                <a className="virtualbox-link" href="https://www.mediafire.com/file/6j39sic48lvkfwn/Jeffery_Snyder_CCSP.pdf/file"><button className="download-button">Download PDF Resume</button></a>
            </div>
            </div>

      </div>
    );
  }
  
  export default About;